<template>
    <Logo :backKey="true" />

    <TopTools :link="true" :lang="true" />

    <Transition>
        <Guide v-if="isGuideOpen" @clickFromChild="toggleGuide" />
    </Transition>

    <Transition>
        <MobileGuide v-if="isMGuideOpen" @closeMGuideFromChild="toggleMGuide" />
    </Transition>

    <router-view />

    <BottomTools />
</template>

<script>
import Logo from "@/components/logo/Logo.vue";
import TopTools from "@/components/tool/TopTools.vue";
import BottomTools from "@/components/tool/BottomTools.vue";
import Guide from "@/components/modal/Guide.vue";
import MobileGuide from "@/components/modal/MobileGuide.vue";
import { ref } from 'vue';

export default {
    components: {
        Logo,
        TopTools,
        BottomTools,
        Guide,
        MobileGuide
    },
    setup() {
        const isGuideOpen = ref(true);
        const isMGuideOpen = ref(false);

        const UserAgent = navigator.userAgent;
        if (UserAgent.match(/iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null) {
            isGuideOpen.value = false;
            isMGuideOpen.value = true;
        } 

        return {
            isGuideOpen,
            isMGuideOpen
        }
    },
    methods: {
        toggleGuide() {
            this.isGuideOpen = !this.isGuideOpen;
        },
        toggleMGuide() {
            this.isMGuideOpen = !this.isMGuideOpen;
        }
    }
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>