<template>
    <img @click="clickGuide" class="guide-modal" src="@/assets/world-guide.png" alt="...">
</template>

<script>
export default {
    methods: {
        clickGuide() {
            this.$emit("clickFromChild");
        }
    }
}
</script>

<style scoped>
.guide-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 50%;
    cursor: pointer;
}
</style>