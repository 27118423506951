<template>
<div class="m-guide-background" @click="closeGuide">
    <div class="sites">
        <img class="guide-icons" src="@/assets/m-guide-sites.png" alt="...">
    </div>
    <div class="lang">
        <img class="guide-icons" src="@/assets/m-guide-lang.png" alt="...">
    </div>
    <div class="main">
        <h2>가이드를 종료하려면 화면을 클릭하세요</h2>
        <h4>Click on the screen exit the guide</h4>
        <img src="@/assets/m-guide-main.png" alt="...">
    </div>
    <div class="screen">
        <img class="guide-icons" src="@/assets/m-guide-screen.png" alt="...">
    </div>  
</div>
</template>

<script>
export default {
    methods: {
        closeGuide() {
            this.$emit("closeMGuideFromChild");
        }
    }
}
</script>

<style scoped>
.m-guide-background {
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    z-index: 1;
}
.sites {
    position: absolute;
    top: 5.2%;
    right: 15%;
}
.lang {
    position: absolute;
    top: 10%;
    right: 5.5%;
}
.screen {
    position: absolute;
    bottom: 10%;
    right: 5.5%;
}
.main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}
.guide-icons {
    width: 100%;
}

@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    .sites {
        top: 5.2%;
        right: 12%;
    }
    .lang {
        top: 10%;
        right: 5.5%;
    }
    .screen {
        right: 5.3%;
    }
    .main {
        width: 100%;
        font-size: xx-small;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .sites {
        top: 7.2%;
        right: 10%;
    }
    .lang {
        top: 15%;
        right: 2.8%;
    }
    .screen {
        right: 3.2%;
        bottom: 14%;
    }
    .main {
        width: 100%;
        font-size: xx-small;
    }
    .main > img {
        width: 20%;
    }
    .main > h2 {
        font-size: large;
    }
    .main > h4 {
        font-size: medium;
    }
    .guide-icons {
        width: 60%;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .sites {
        top: 7%;
        right: 15%;
        width: 130px;
    }
    .lang {
        top: 15%;
        right: 6.2%;
        width: 90px;
    }
    .screen {
        bottom: 15%;
        right: 6.1%;
        width: 80px;
    }
    .main {
        width: 100%;
        font-size: xx-small;
    }
    .main > img {
        width: 15%;
    }
    .guide-icons {
        width: 100%;
    }
}

@media (max-width: 481px) {
    .sites {
        top: 6%;
        right: 25%;
        width: 130px;
    }
    .lang {
        right: 7.2%;
        width: 90px;
    }
    .main > img {
        width: 40%;
    }
    .main > h2 {
        font-size: larger;
    }
    .main > h4 {
        font-size: large;
    }
    .screen {
        right: 7.2%;
        width: 80px;
    }
    .main {
        width: 100%;
    }
    .guide-icons {
        width: 100%;
    }
}
</style>